<template>

    <nav class="pagination-wrapper d-flex justify-content-center justify-content-lg-end">
        <ul class="pagination my-4">
            <li v-if="showPrevLinks > 0 || isMobile" class="page-item">
                <a href="#"
                   class="page-link page-link-prev d-flex align-items-center"
                   @click.prevent="change(current - 1)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    <span>Previous</span>
                </a>
            </li>

            <li v-if="current > showLinks && !isMobile" class="page-item">
                <a href="#"
                   class="page-link"
                   @click.prevent="change(1)"
                >
                    1
                </a>
            </li>
            <li v-if="current > showLinks && !isMobile" class="page-item">
                <span class="page-link">...</span>
            </li>

            <template v-for="page in max">
                <li v-if="checkPrevLinks(page) && !isMobile" :key="page" class="page-item">
                    <a href="#"
                       class="page-link"
                       @click.prevent="change(page)"
                    >
                        {{ page }}
                    </a>
                </li>

                <li v-if="page == current && !isMobile" :key="page" class="page-item active" aria-current="page">
                    <span class="page-link">{{ current }}<span class="sr-only">(current)</span></span>
                </li>
                <li v-else-if="page == current && isMobile" :key="page" class="page-item active px-1" aria-current="page">
                    <Input style="width: 70px" className="text-center px-1" ref="input" name="pagination" type="number" :value="current" @change="changeInput"/>
                </li>

                <li v-if="checkNextLinks(page) && !isMobile" :key="page" class="page-item">
                    <a href="#"
                       class="page-link"
                       @click.prevent="change(page)"
                    >
                        {{ page }}
                    </a>
                </li>
            </template>
            <li class="page-item"><span class="page-link" style="pointer-events: none;">z</span></li>
            <li class="page-item"><span class="page-link" style="pointer-events: none;">{{ max }}</span></li>

            <li v-if="(showNextLinks > 0 || isMobile) && current < max" class="page-item">
                <a href="#"
                   class="page-link page-link-next d-flex align-items-center"
                   @click.prevent="change(current + 1)"
                >
                    <span>Next</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="ml-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import Input from "@/components/form/Input";
export default {
    name: "Pagination",
    components: {Input},
    data: () => ({
        showLinks: 4,
        showPrevLinks: null,
        showNextLinks: null,
        isMobile: false,
    }),
    mounted() {
        window.addEventListener('resize', this.handleResizeWindow);
        this.handleResizeWindow();

        this.showPrevLinks = this.showLinks / 2;
        this.showNextLinks = this.showLinks / 2;
        this.changeNumberShowPrevNext();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResizeWindow);
    },
    props: {
        current: {
            type: Number,
            validator: (value) => value > 0,
            required: true,
        },
        max: {
            type: Number,
            validator: (value) => value > 0,
            required: true,
        }
    },
    watch: {
        current() {
            this.changeNumberShowPrevNext();
        }
    },
    methods: {
        change(page) {
            if(page <= this.max){
                this.$emit('change', page);
            }
        },
        changeInput({target, type}) {
            if(target && type === 'change' && parseInt(target.value) < this.max){
                this.$emit('change', parseInt(target.value));
            }
        },

        changeNumberShowPrevNext() {
            const {max, current, showLinks} = this;
            const diffNext = showLinks - (max - (max - current)) + 1;
            const diffPrev = max - current;
            if (diffNext > 0 || diffPrev >= 0) {
                const setShowNextPrevLinks = (diff) => {
                    this.showNextLinks = diff;
                    this.showPrevLinks = showLinks - diff;
                }

                if (current <= showLinks - (showLinks / 2) + 1 && diffNext <= showLinks) {
                    setShowNextPrevLinks(diffNext);
                } else if (current >= max - (showLinks / 2) && diffPrev <= showLinks) {
                    setShowNextPrevLinks(diffPrev);
                }
            }
        },

        checkPrevLinks(page) {
            const {current, showPrevLinks} = this;
            return page < current && current - page <= showPrevLinks;
        },
        checkNextLinks(page) {
            const {current, showNextLinks} = this;
            return page > current && page - current <= showNextLinks;
        },
        handleResizeWindow() {
            this.isMobile = window.innerWidth < 992
        },
    }
}
</script>

<style scoped lang="scss">
.page-link{
    &-next,
    &-prev{
        span{
            @include media-breakpoint-down-px(399) {
                display: none;
            }
        }
    }
}
</style>