<template>
    <div class="card">
        <form class="card-body px-lg-4 py-3 py-lg-5" @submit.prevent="handleSubmit">
            <div class="row">
                <div class="col-md-6">
                    <Input name="first_name"
                           label="First Name"
                           placeholder="Enter your first name"
                           @change="handleChange"
                           :disabled="true"
                           :value="disabledForm.first_name"
                    />
                </div>
                <div class="col-md-6 mt-4 mt-md-0">
                    <Input name="last_name"
                           label="Last Name"
                           placeholder="Enter your last name"
                           @change="handleChange"
                           :disabled="true"
                           :value="disabledForm.last_name"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mt-4">
                    <InputPhone name="phone_number"
                                label="Phone number"
                                :value="form.phone_number"
                                :v="$vServer.phone_number || $v.form.phone_number"
                                @change="handleChange"/>
                </div>
            </div>

            <hr class="my-4">

            <Input class="mt-4"
                   name="address1"
                   label="Address"
                   placeholder="Enter your address"
                   @change="handleChange"
                   :disabled="true"
                   :value="disabledForm.address1"
            />

            <div class="row">
                <div class="col-md-3 mt-4">
                    <Input name="post_code"
                           label="Postcode"
                           placeholder="Enter your postcode"
                           @change="handleChange"
                           :disabled="true"
                           :value="disabledForm.post_code"
                    />
                </div>
                <div class="col-md-4 mt-4">
                    <Input name="city"
                           label="City"
                           placeholder="Enter your city"
                           @change="handleChange"
                           :disabled="true"
                           :value="disabledForm.city"
                    />
                </div>
                <div class="col-md-5 mt-4">
                    <Input name="country"
                           label="Country"
                           placeholder="Enter your country"
                           @change="handleChange"
                           :disabled="true"
                           :value="disabledForm.country"
                    />
                </div>
            </div>
            <div class="text-right mt-5">
                <button type="submit" class="btn btn-primary btn-lg" style="min-width: 200px;">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
import Input from "@/components/form/Input";
import InputPhone from "@/components/form/InputPhone";
import {mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import {scrollToError} from "@/helpers";
import {EDIT_USER_URL} from "@/config/api";

export default {
    name: "CompanyInformationForm",
    components: {Input, InputPhone},
    data: () => ({
        submitted: false,
        form: {
            phone_number: null,
        },
        disabledForm: {
            first_name: null,
            last_name: null,
            address1: null,
            post_code: null,
            city: null,
            country: null,
        }
    }),
    created() {
        for (const [name,value] of Object.entries( {...this.user, ...this.user.address, ...this.user.contact})){
            if(name in this.disabledForm)
                this.disabledForm[name] = value;
            if(name in this.form)
                this.form[name] = value;
        }
    },
    validations: {
        form: {
            // first_name: {required},
            // last_name: {required},
            phone_number: {required},
            // address1: {required},
            // post_code: {required},
            // city: {required},
            // country: {required},
        }
    },
    methods: {
        handleChange({name, value}) {
            if (name in this.form && this[name] !== value) {
                this.form[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el));
            } else {
                await this.submit();
            }
        },

        async submit() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();
                const token = this.$store.getters['auth/token'];

                const {data, status} = await this.$http.put(EDIT_USER_URL, this.form, {
                    headers: {Authorization: `Bearer ${token}`},
                    timeout: 10000,
                });

                await this.$store.dispatch('auth/fetchUser');

                this.$store.dispatch('serverNotice/setNotice', {
                    message: data.message,
                    status: status,
                });


            } catch (e) {
                const {data, status} = e.response;

                if (!data.status)
                    data.status = status;
                this.$store.dispatch('serverNotice/setNotice', data);
                this.resetForm();
                this.$nextTick(() => scrollToError(this.$el))
            }
            this.submitted = false;
            this.$root.$preloader.finish();
        },

        resetForm() {
            const errors = this.$store.getters['serverNotice/errors'];
            for (const [name,] of Object.entries(errors)) {
                this.form[name] = null;
            }
        }

    },
    computed: {
        ...mapGetters({
            'user': 'auth/user',
            '$vServer': 'serverNotice/errors'
        }),
    },
}
</script>
