<template>
    <div v-if="loaded" class="card">
        <div class="card-body px-lg-4 py-3 py-lg-5">
            <h4 class="text-primary font-weight-semibold mb-3">Balance history</h4>

            <TableBalanceHistory class="table-responsive"
                                 :balance-history="balanceHistory"/>

            <Pagination :current="currentPage"
                        :max="balanceHistory.meta.last_page"
                        @change="handleChangePagination"
            />
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableBalanceHistory from "@/shared/settings/TableBalanceHistory";

export default {
    name: "CreditBalanceForm",
    components: {TableBalanceHistory, Pagination},
    data: () => ({
        loaded: false,
        currentPage: 1,
        balanceHistory: null,
    }),
    async created() {

        this.balanceHistory = await this.fetchBalanceHistory();
        this.currentPage = this.balanceHistory.meta.current_page;
        this.checkMaxPage(this.currentPage);
        this.loaded = true;
    },

    watch: {
        async $route() {
            this.checkMaxPage(this.$route.params.page)
            this.currentPage = this.$route.params.page || 1;
            this.balanceHistory = await this.fetchBalanceHistory();
        }
    },
    methods: {
        async fetchBalanceHistory(){
            return await this.$store.dispatch('user/fetchBalanceHistory', {
                ...this.$route.params
            })
        },
        handleChangePagination(page) {
            const params = {
                ...this.$route.params,
                ...{page: page > 1 ? page : undefined}
            };

            this.$router.push({name: 'settings', params});
        },
        checkMaxPage(page) {
            page = page !== undefined ? parseInt(page) : 1;
            if (page > this.balanceHistory.meta.last_page) {
                this.$router.push({
                    name: 'settings',
                    params: {
                        ...this.$route.params,
                        ...{page: this.balanceHistory.meta.last_page}
                    }
                });
            }
        }
    }

}
</script>

<style scoped>

</style>