<template>
    <Modal id="currency-modal"
           size="lg"
           :centered="true"
           :show="show"
           :staticBackdrop="true"
           v-on="$listeners"
    >
        <form class="pt-6 px-4 px-md-6 text-center" @submit.prevent="handleSubmit">

            <h2 class="h3 font-weight-semibold position-relative mb-5">Select a currency <span
                class="font-weight-light">and</span> enter the reason <span
                class="font-weight-light"><br>why you want to change the currency</span></h2>

            <div class="row text-left">
                <div class="col-lg-8 mx-auto">
                    <Select class="mb-4"
                            label="Select currency"
                            name="currency"
                            :selected="form.currency"
                            :disabled="disabled"
                            @change="handleChange"
                            :options="currenciesCast"
                            :v="$vServer.currency || $v.form.currency"

                    />
                </div>

                <div class="col-lg-8 mx-auto">
                    <Textarea class="mb-4"
                              label="Reason"
                              name="reason"
                              :disabled="disabled"
                              :value="form.reason"
                              @change="handleChange"
                              :v="$vServer.reason || $v.form.reason"

                    />
                </div>
                <div class="col-8 mx-auto">
                    <button class="btn btn-primary btn-lg w-100"
                            :disabled="disabled"
                    >
                        Send
                    </button>
                </div>
            </div>
            <div class="mt-6 mb-4">
                <img src="@/assets/img/logo_gepa.svg" alt="Gepa Logistics">
            </div>
        </form>
    </Modal>
</template>

<script>
import {mapGetters} from "vuex";
import Modal from "@/components/Modal";
import Select from "@/components/form/Select";
import Textarea from "@/components/form/Textarea";
import {required} from "vuelidate/lib/validators";
import {CHANGE_CURRENCY_USER_URL} from "@/config/api";
import {scrollToError} from "@/helpers";

export default {
    name: "CurrencyModal",
    components: {Textarea, Modal, Select},
    data: () => ({
        submitted: false,
        disabled: false,
        form: {
            reason: null,
            currency: null
        }
    }),
    props: {
        show: {
            default: false,
            type: Boolean,
        },
        userCurrency: {
            type: String
        },
        currencies: {
            type: [Object, Array, null, undefined]
        }
    },
    validations: {
        form: {
            reason: {required},
            currency: {required}
        }
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                // clear server errors
                this.$store.dispatch('serverNotice/clearErrors');
                this.form[name] = value;
            }
        },
        async handleSubmit() {
            if (this.submitted) return false;
            this.$v.form.$touch();
            if (!this.$v.$invalid) {
                await this.submit();
            }
        },

        async submit() {
            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();
                const token = this.$store.getters['auth/token'];

                const {data, status} = await this.$http.patch(CHANGE_CURRENCY_USER_URL, this.form, {
                    headers: {Authorization: `Bearer ${token}`},
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', {
                    message: data.message,
                    status: status,
                });

                this.disabled = true;
            } catch (e) {
                const {data, status} = e.response;

                if (!data.status)
                    data.status = status;

                this.$store.dispatch('serverNotice/setNotice', data);
                this.$nextTick(() => scrollToError(this.$el))
            }
            this.$v.$reset();
            this.resetForm();
            this.$root.$preloader.finish();
            this.submitted = false;
        },

        resetForm() {
            this.form.reason = null;
            this.form.currency = null;
        }

    },
    computed: {
        ...mapGetters({
            'user': 'auth/user',
            '$vServer': 'serverNotice/errors'
        }),

        currenciesCast(){
            let currencies = {};
            for (const [code, currency] of Object.entries(this.currencies)) {
                if(this.userCurrency !== code){
                    currencies[code] = currency
                }
            }
            return currencies;
        }
    },
}
</script>

<style scoped lang="scss">

</style>