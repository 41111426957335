<template>
    <div class="card">
        <form class="card-body px-lg-4 py-3 py-lg-5" @submit.prevent="handleSubmit">
            <div class="col-xl-8 col-xl3-6">
                <Input name="password_current"
                       type="password"
                       label="Current Password"
                       placeholder="Enter your current password"
                       autocomplete="new-password"
                       @change="handleChange"
                       :value="form.password_current"
                       :v="$vServer.password_current || $v.form.password_current"/>
            </div>
            <span class="w-100"></span>
            <div class="col-xl-8 col-xl3-6">
                <Input class="mt-4"
                       name="password"
                       type="password"
                       label="New Password"
                       placeholder="Enter your new password (6 or more characters)"
                       autocomplete="new-password"
                       @change="handleChange"
                       :value="form.password"
                       :v="$vServer.password || $v.form.password"/>
            </div>
            <span class="w-100"></span>
            <div class="col-xl-8 col-xl3-6">
                <Input class="mt-4"
                       name="password_confirmation"
                       type="password"
                       label="Confirm new password"
                       placeholder="Confirm your new password"
                       autocomplete="new-password"
                       @change="handleChange"
                       :value="form.password_confirmation"
                       :v="$vServer.password_confirmation || $v.form.password_confirmation"/>
            </div>
            <div class="col-xl-8 col-xl3-6 text-right mt-4">
                <button type="submit" class="btn btn-primary btn-lg" style="min-width: 200px;">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
import Input from "@/components/form/Input";
import {mapGetters} from "vuex";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import {scrollToError} from "@/helpers";
import {CHANGE_PASSWORD_USER_URL} from "@/config/api";

export default {
    name: "PasswordForm",
    components: {Input},
    data: () => ({
        submitted: false,
        form: {
            password_current: null,
            password: null,
            password_confirmation: null,
        }
    }),
    validations: {
        form:{
            password_current: {
                required,
            },
            password: {
                required,
                minLength: minLength(6),
            },
            password_confirmation: {
                required,
                minLength: minLength(6),
                sameAs: sameAs('password')
            },
        }
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                this.form[name] = value;
            }
        },
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                await this.submit();
            }
        },

        async submit() {
            if (this.submitted) return false;

            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();
                const token = this.$store.getters['auth/token'];

                const {data, status} = await this.$http.post(CHANGE_PASSWORD_USER_URL, this.form, {
                    headers: {Authorization: `Bearer ${token}`},
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', {
                    message: data.message,
                    status: status,
                });
            } catch (e) {
                const {data, status} = e.response;

                if (!data.status)
                    data.status = status;

                this.$store.dispatch('serverNotice/setNotice', data);
                this.$nextTick(() => scrollToError(this.$el))

            }
            this.$v.$reset();
            this.resetForm();
            this.$root.$preloader.finish();
            this.submitted = false;
        },

        resetForm() {
            this.form.password_current = null;
            this.form.password = null;
            this.form.password_confirmation = null;
        }
    },
    computed: {
        ...mapGetters({
            'user': 'auth/user',
            '$vServer': 'serverNotice/errors'
        }),
    },
}
</script>