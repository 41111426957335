<template>
    <Table
        class="mt-5 table-balance-history"
        :cols="cols"
        :data="castBalanceHistory"
    >
        <template v-slot:id="slopProps">
            <strong class="d-block text-center font-weight-medium">{{ slopProps.row.id || '-' }}</strong>
        </template>

        <template v-slot:detail="slopProps">
            <span v-if="slopProps.row.booking">
                <strong class="font-weight-medium">Delivery: </strong>
                <router-link :to="{name: 'dashboard'}"
                >
                    #{{ slopProps.row.booking.short_reference_key }}
                </router-link>
            </span>
            <strong v-else-if="slopProps.row.type === 'add'"
                    class="font-weight-medium">
                Balance recharge
            </strong>
        </template>

        <template v-slot:amount="slopProps">
            <span
                class="font-weight-medium"
                :class="{
                    'text-danger': slopProps.row.type === 'subtract',
                    'text-success': slopProps.row.type === 'add',
            }">
                {{ slopProps.row.amount || '-' }}
            </span>
        </template>
    </Table>
</template>

<script>
import moment from "moment";
import Table from "@/components/table/Table";

export default {
    name: "TableBalanceHistory",
    components: {Table},
    props: {
        balanceHistory: Object,
    },
    data: () => ({
        cols: {
            id: {label: 'Id'},
            detail: {label: 'Detail'},
            date: {label: 'Date'},
            type: {label: 'Type', show: false},
            booking: {label: 'Booking', show: false},
            amount: {label: 'Amount'},
        }
    }),

    computed: {
        castBalanceHistory() {
            const output = [];
            if (this.balanceHistory.data) {
                for (const [, item] of Object.entries(this.balanceHistory.data)) {
                    output.push({
                        id: item.meta_data.booking ? `#${item.meta_data.booking.short_reference_key}` : null,
                        date: moment(item.created_at).format('DD-MM-YYYY'),
                        booking: item.meta_data.booking,
                        type: item.event_properties.type,
                        amount: item.meta_data.amount_converted.formatted,
                    })
                }
            }
            return output;
        }
    }
}
</script>

<style lang="scss">
.table-balance-history {
    .table {
        &-body {
            &-column {
                &-id {
                    width: 150px;
                }

                &-date,
                &-amount {
                    width: 250px;

                    @include media-breakpoint-down('xl2'){
                        width: 150px;
                    }
                }

                &-date{
                    white-space: nowrap;
                }
            }
        }
    }
}

</style>