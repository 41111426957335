<template>
    <PrivateLayout v-if="loaded">
        <div class="card border-none mt-4">
            <div class="card-body py-lg-4 px-lg-5">
                <h4 class="text-primary font-weight-semibold mt-3">Account settings</h4>
                <ul class="nav nav-tabs mt-6">
                    <li class="nav-item mb-0" v-for="tab of Object.keys(tabs)" :key="tab">
                        <router-link :to="{name: 'settings', params: {tab}}"
                                     class="nav-link"
                                     active-class="active"
                        >{{ tabs[tab] }}
                        </router-link>
                    </li>
                </ul>

                <div class="tab-content">
                    <CompanyInformationForm v-if="$route.params.tab === 'account'"/>
                    <PasswordForm v-if="$route.params.tab === 'password'"/>
                    <CreditBallanceForm v-if="$route.params.tab === 'balance'"/>
                    <OtherSettingsForm v-if="$route.params.tab === 'other'"/>
                </div>
            </div>
        </div>

        <CertifiedLogos class="mt-6 pt-5"/>
    </PrivateLayout>
</template>

<script>
import CertifiedLogos from "@/components/CertifiedLogos";
import {name} from "@/config/app";
import {auth_route} from "@/config/auth";
import CompanyInformationForm from "@/shared/settings/CompanyInformationForm";
import PasswordForm from "@/shared/settings/PasswordForm";
import CreditBallanceForm from "@/shared/settings/CreditBalanceForm";
import OtherSettingsForm from "@/shared/settings/OtherSettingsForm";

export default {
    name: "Settings",
    middleware: 'auth',
    meta: {
        title: `Account Settings - ${name}`
    },
    components: {OtherSettingsForm, CreditBallanceForm, PasswordForm, CompanyInformationForm, CertifiedLogos},
    data: () => ({
        loaded: false,
        defaultTab: 'account',
        tabs: {
            account: 'Company information',
            password: 'Password',
            balance: 'Credit Balance',
            other: 'Other settings',
        }
    }),
    async beforeCreate() {
        await Promise.all([
            this.$store.dispatch('units/fetchUnits'),
            this.$store.dispatch('units/fetchCurrencies'),
        ])
        this.loaded = true;
    },

     created() {
        const tab = this.$route.params.tab;
        if (!tab) {
            this.$router.push({name: 'settings', params: {tab: this.defaultTab}});
        } else if (Object.keys(this.tabs).indexOf(tab) === -1) {
            this.$router.push(`/${auth_route}`)
        }
    },
    beforeRouteUpdate(to, from, next) {
        const tab = to.params.tab;
        if (!tab) {
            next({name: 'settings', params: {tab: this.defaultTab}});
        } else if (Object.keys(this.tabs).indexOf(tab) > -1) {
            next();
        } else {
            next(`/${auth_route}`);
        }
    }
}
</script>

<style scoped lang="scss">
.nav {
    &-tabs {
        border-bottom: none;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: -10px;
        padding-bottom: 10px;
        position: relative;
        z-index: 1;


        .nav-link.active,
        .nav-item.show .nav-link {
            border-color: $blue-light !important;
            background-color: $blue-light !important;
            pointer-events: none;
        }

        .nav-link:hover,
        .nav-link:focus {
            border-color: $gray-200;
            background-color: $gray-200;
        }
    }

    &-item {
        white-space: nowrap;

        & + & {
            margin-left: 2px;
        }
    }

    &-link {
        border-color: $gray-100;
        background-color: $gray-100;
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        padding: 0.75rem 2rem !important;
        width: auto !important;
    }
}
</style>