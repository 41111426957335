<template>
    <div v-if="user"
        class="card">
        <form class="card-body px-lg-4 py-3 py-lg-5" @submit.prevent="handleSubmit">
            <div class="d-flex align-items-end">
                <div class="col-xl-8 col-xl3-5">
                    <Select label="Your currency"
                            name="user_currency"
                            :disabled="true"
                            :selected="user.settings.currency"
                            :options="currenciesCast"
                    />

                </div>
                <div class="col-xl-8 col-xl3-1">
                    <button class="btn btn-primary py-2"
                            @click.prevent="showCurrencyModal = true"
                    >
                        Change
                    </button>
                </div>
            </div>

            <span class="w-100"></span>
            <div class="col-xl-10 col-xl3-8 mt-4">
                <label>Units</label>
                <div class="d-md-flex">
                    <template v-for="(unit, unitValue) in units">
                        <Radio class="mr-5"
                               :key="unitValue"
                               :id="`unit-${unitValue}`"
                               name="unit"
                               :label="unit.label"
                               :value="unitValue"
                               :checked="unitValue === user.settings.unit"
                               :v="$vServer['service_details.unit'] || $v.form.unit"
                               :show-error="false"
                               @change="handleChange"
                        />
                    </template>
                </div>

            </div>
            <div class="col-md-6 text-right mt-4">
                <button type="submit" class="btn btn-primary btn-lg" style="min-width: 200px;">Save</button>
            </div>
        </form>

        <CurrencyModal :show="showCurrencyModal"
                       @hideModal="showCurrencyModal = false"
                       :user-currency="user.settings.currency"
                       :currencies="currenciesCast"
        />


    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {scrollToError} from "@/helpers";
import {CHANGE_SETTINGS_USER_URL} from "@/config/api";
import Radio from "@/components/form/Radio";
import {required} from "vuelidate/lib/validators";
import Select from "@/components/form/Select";
import CurrencyModal from "@/shared/settings/CurrencyModal";

export default {
    name: "OtherSettingsForm",
    components: {Radio, Select, CurrencyModal},
    data: () => ({
        submitted: false,
        showCurrencyModal: false,
        form: {
            unit: null,
        }
    }),

    created() {
        this.form.unit = this.user.settings.unit;
    },

    validations: {
        form: {
            unit: {required}
        },
    },
    methods: {
        handleChange({name, value}) {
            if (this[name] !== value) {
                this.$store.dispatch('serverNotice/clearErrors');
                this.form[name] = value;
            }
        },

        async handleSubmit() {
            if (this.submitted) return false;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$nextTick(() => scrollToError(this.$el))
            } else {
                await this.submit();
            }
        },

        async submit() {
            this.submitted = true;
            this.$store.dispatch('serverNotice/clearNotice');

            try {
                this.$root.$preloader.start();
                const token = this.$store.getters['auth/token'];

                const {data, status} = await this.$http.patch(CHANGE_SETTINGS_USER_URL, this.form, {
                    headers: {Authorization: `Bearer ${token}`},
                    timeout: 10000,
                });

                this.$store.dispatch('serverNotice/setNotice', {
                    message: data.message,
                    status: status,
                });

                this.disabled = true;
            } catch (e) {
                const {data, status} = e.response;

                if (!data.status)
                    data.status = status;

                this.$store.dispatch('serverNotice/setNotice', data);
                this.$nextTick(() => scrollToError(this.$el))
            }
            this.$root.$preloader.finish();
            this.submitted = false;
        }
    },
    computed: {
        ...mapGetters({
            'user': 'auth/user',
            'units': 'units/units',
            'currencies': 'units/currencies',
            '$vServer': 'serverNotice/errors'
        }),

        currenciesCast() {
            let currencies = {};
            for (const [code, currency] of Object.entries(this.currencies.currencies)) {
                const {name, symbol} = currency;
                currencies[code] = `${symbol} (${code}, ${name})`
            }
            return currencies;
        }
    },
}
</script>